import React from "react"
import styled from "styled-components"
import SuggestionInput from "../SuggestionInput"

const Title = () => {
  return (
    <Wrapper>
      <SuggestionInput name={"title"} label={"Tittel"} />
    </Wrapper>
  )
}

export default Title

const Wrapper = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
`
