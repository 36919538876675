import React from "react"
import { graphql } from "gatsby"
import { Layout } from "@staccx/base"
import styled from "styled-components"
import DefaultLayout from "../layouts"
import SEO from "../components/seo"
import PageLayout from "../components/PageLayout"
import SuggestionForm from "../components/suggestion/SuggestionForm"
import GraphQLErrorList from "../components/graphql-error-list"

export const query = graphql`
  query SuggestPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
  }
`

const SuggestWords = props => {
  const { data, errors } = props
  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }
  return (
    <DefaultLayout>
      <SEO title="Whatis.Finance" location={props.location} />
      <PageLayout siteTitle={data.site.title}>
        <OuterWrapper>
          <Wrapper>
            <SuggestionForm />
          </Wrapper>
        </OuterWrapper>
      </PageLayout>
    </DefaultLayout>
  )
}

export default SuggestWords

const Wrapper = styled.div`
  width: 80vh;
  margin-top: 15vh;
  align-content: center;
  @media (max-width: 768px) {
    margin-top: 2vh;
  }
`
const OuterWrapper = styled.div`
  display: flex;
  justify-content: center;
`
