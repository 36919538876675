import * as Yup from "yup"

export default Yup.object()
  .shape({
    currentContent: Yup.object()
      .shape({
        blockMap: Yup.object().required()
      })
      .required()
  })
  .required("Dette feltet er påkrevd")
