import React from "react"
import SuggestionInput from "../SuggestionInput"

const Abbreviation = () => {
  return (
    <div>
      <SuggestionInput name={"abbreviation"} label={"Forkortelse"} />
    </div>
  )
}

export default Abbreviation

