import React, { useState } from "react"
import { Editor, EditorState } from "draft-js"
import "draft-js/dist/Draft.css"
import { useFormikContext } from "formik"
import styled from "styled-components"

const Body = () => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const { setFieldValue } = useFormikContext()
  const myInp = React.createRef()

  const handleChange = value => {
    setEditorState(value)
    setFieldValue("body", value.toJS())
  }

  return (
    <div>
      <Label>Beskrivelse</Label>
      <Wrapper
        onClick={() => {
          myInp.current.focus()
        }}
      >
        <Editor
          id="editor"
          ref={myInp}
          editorState={editorState}
          onChange={handleChange}
        />
      </Wrapper>
    </div>
  )
}

export default Body

const Label = styled.label`
  color: white;
  font-weight: bold;
  font-size: 16px;
`

const Wrapper = styled.div`
  z-index: 1000000;
  min-height: 300px;
  padding: 5px;
  color: white;
  border: 0;
  border-bottom: 1px solid white;
  background-color: #242f30;
  @media (max-width: 768px) {
    min-height: 150px;
  }
`
