import * as Yup from "yup"
import abbreviationSchema from "./abbreviation/Abbreviation.schema"
import titleSchema from "./title/Title.schema"
import bodySchema from "./body/Body.schema"

export default Yup.object().shape({
  abbreviation: abbreviationSchema,
  title: titleSchema,
  body: bodySchema
})
