import sanityClient from "@sanity/client"
import uuid from "uuid/v5"

const client = sanityClient({
  projectId: process.env.GATSBY_SANITY_PROJECT_ID,
  dataset: process.env.GATSBY_SANITY_DATASET,
  useCdn: true,
  token: process.env.GATSBY_SANITY_TOKEN
})

export const suggest = ({ values, setLoading }) => {
  const { abbreviation, title, body } = values
  const blocks = Object.entries(body.currentContent.blockMap).map(
    ([, block], index) => {
      // const _key = uuid(block.key, process.env.NAMESPACE)
      return {
        _type: "block",
        children: [
          {
            _type: "span",
            marks: [],
            text: block.text
          }
        ],
        markDefs: [],
        style: "normal"
      }
    }
  )

  const suggestion = {
    _id: uuid(abbreviation, process.env.NAMESPACE),
    _type: "post",
    title,
    abbreviation: {
      _type: "slug",
      current: abbreviation
    },
    publishedAt: new Date().toISOString(),
    slug: {
      _type: "slug",
      current: abbreviation
    },
    status: "pending",
    body: blocks
  }
  console.log(suggestion)
  return client
    .createIfNotExists(suggestion)
    .then(document => {
      setLoading(true)
      console.log("Created document", document)
    })
    .catch(console.error)
}
