import React from "react"
import { Input, theming } from "@staccx/base"
import styled, { css } from "styled-components"
import { ErrorMessage, useFormikContext } from "formik"

const SuggestionInput = ({ name, ...props }) => {
  const { setFieldValue } = useFormikContext()
  return (
    <div>
      <StyledInput
        location={"suggestWords"}
        onChange={e => setFieldValue(name, e.target.value)}
        {...props}
      />
      <ErrorMessage name={name}>
        {msg => console.log(msg) || <Error>{msg}</Error>}
      </ErrorMessage>
    </div>
  )
}
const Error = styled.div`
  color: red;
`

const StyledInput = styled(Input)`
  padding-bottom: ${theming.spacing.medium};
  input[type="search"] {
    -webkit-appearance: none !important;
  }
  input {
    color: ${p => p.pageColors && p.pageColors[1]};
    caret-color: ${p => p.pageColors && p.pageColors[1]};
    font-family: ${theming.fontFamily.body};
    font-weight: normal;
    border: 0;
    border-radius: 0;
    border-bottom: 1px solid white;

    background-color: #242f30;
    &,
    :hover,
    :focus {
      background-color: #242f30;
    }
  }
  ${p =>
    p.location === "suggestWords"
      ? css`
          input {
            text-align: left;
            color: white;
            font-size: ${theming.font("h3")};
          }
        `
      : css`
          input {
            font-size: ${theming.font("h3")};
            padding-left: ${theming.spacing.small};
            padding-right: ${theming.spacing.small};
          }
        `}
  @media (max-width: 768px) {
    font-size: 36px;
    ${p =>
      p.isSearchMode &&
      css`
        input {
          color: ${p => p.pageColors && p.pageColors[1]};
          caret-color: ${p => p.pageColors && p.pageColors[1]};
          text-align: left;
          font-size: 24px;
        }
      `}
  }
`

export default SuggestionInput
