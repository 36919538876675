import React, { useState } from "react"
import styled from "styled-components"
import { Formik, Form } from "formik"
import { Button, Alert, theming } from "@staccx/base"
import Abbreviation from "./abbreviation/Abbreviation"
import validationSchema from "./SuggestionForm.schema"
import Title from "./title/Title"
import Body from "./body/Body"
import { suggest } from "./client"
import { navigate } from "gatsby"

const SuggestionForm = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{
        abbreviation: "",
        title: "",
        body: {}
      }}
      onSubmit={values => {
        suggest({ values, setLoading }).then(() => {
          console.log(values)
          setIsOpen(true)
          setTimeout(() => {
            navigate("/")
          }, 1000)
        })
      }}
    >
      {({ handleSubmit }) => {
        return (
          <Form>
            <Abbreviation />
            <Title />
            <Body />
            {!loading && (
              <StyledButton type={"submit"} onClick={handleSubmit}>
                Send
              </StyledButton>
            )}
            {isOpen && (
              <Alert type={"success"}>
                <span>Skjemaet er innsendt, takk!</span>
              </Alert>
            )}
          </Form>
        )
      }}
    </Formik>
  )
}

export default SuggestionForm

const StyledButton = styled(Button)`
  margin-top: ${theming.spacing.mediumPlus};
  margin-bottom: 5px;
  background-color: #242f30;
  border-radius: 0;
  :hover {
    background-color: #516c6d;
  }
`
